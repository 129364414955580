// https://www.w3schools.com/w3css/w3css_color_generator.asp



//          /!\   si on modifie ces valeures, il faut aussi modifier dans 'helpers/constants'

$C1l5: #faf0f7;
$C1l4: #f0cee4;
$C1l3: #e19dc9;
$C1l2: #d26cae;
$C1l1: #c23b93;

$C1: #902c6d;

$C1d1: #822862;
$Cld2: #732357;
$C1d3: #651f4d;
$C1d4: #571b42;
$C1d5: #481637;

/*
$container-max-widths: (
  sm: 440px,
  md: 920px,
  lg: 1160px,
  xl: 1340px,
  xxl: 1520px
);
*/

$primary:       $C1;
$secondary:    	#a6b1bd;
$success:       #00B74A;
$info:          #39C0ED;
$warning:       #FFA900;
$danger:        #F93154;
$light:         $C1l5;
$dark:          $C1d5;



$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);


@import '~bootstrap/scss/bootstrap.scss';
