@import './theme.scss';

.color-c1 { color: $C1 !important}
.color-c1l1 { color: $C1l1 !important}
.color-c1l2 { color: $C1l2 !important}
.color-c1l3 { color: $C1l3 !important}
.color-c1l4 { color: $C1l4 !important}
.color-c1d1 { color: $C1d1 !important}
.color-c1d2 { color: $Cld2 !important}
.color-c1d3 { color: $C1d3 !important}

.color-white { color:white !important;}




.bg-white { background-color: white !important; }
.bg-red { background-color: red !important; }
.bg-green { background-color: green !important; }
.bg-yellow { background-color: yellow !important; }
.bg-cyan{ background-color: cyan !important; }
.bg-orange { background-color: orange !important; }



.btn-xs{
	padding: .20rem .30rem .20rem .30rem;
    font-size: .500rem;
    line-height: 1.0;
    border-radius: .15rem;
}
.btn{       		cursor: pointer;    }
.cursor-pointer{    cursor: pointer;    }
.row{       margin: 0px !important;        }
.hidden{    display: none;      }


.zxTable{
	border: 1px solid $C1 ;
	margin: 0px;
	width: 100%;
	background-color: white;
	thead{
		border-bottom: 1px solid $C1;
		tr{
			border-bottom: 1px solid $C1 ;
			color:$C1;
		}
		.zxTrTitle{
			color:$C1;
			border-bottom: 1px solid $C1 ;
			background-color: $C1l4;
			th{
				padding: 5px;
				font-size: 1.2em;
			}
		}
		.zxTrColumnsHeader{
			color:$C1;
			th{
				border-left: 1px solid $C1 ;
			}
		}
	}
	tbody{
		tr{
			border-bottom: 1px solid $C1 ;
			&:hover{
				background-color: $C1l3;
			}
			td{
				border-left: 1px solid $C1 ;
			}
		}
	}

}





.zxForm{

	.zxFormHeader{

		.zxFormTitle{

			//color:$C1d3;
		}
	}

		.formError{
			color:red;
		}
	.zxFormFooter{
		background-color: lime;
		.xxxxxxxxxxx{
			background-color: pink;
		}
	}

}
